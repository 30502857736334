<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <p v-if="loading">
          <img
            :src="require('@/assets/images/loading.svg')"
            style="width: 40px; height: 40px"
            alt=""
          />
          جاري التحميل...
        </p>
        <div class="row">
          <div class="col-12 g">
            <button class="btn btn-success" @click="onlySuccess()">
              <i class="fa fa-check"></i>
              الناجحة فقط
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-secondary" @click="toExcel()">
              <i class="fa fa-download"></i>
              تصدير المعروض
            </button>
          </div>
        </div>
        <div class="col-12 table-responsive" v-if="!loading" id="table">
          <table class="table table-hover table-bordered">
            <thead>
              <th>المستخدم</th>
              <th>رقم الطلب</th>
              <th>التاريخ</th>
              <th>المبلغ</th>
              <th>الحالة</th>
              <th>يدوي</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order._id">
                <td>
                  <span
                    class="text-success"
                    v-if="users[order.user_id]"
                    @click="$router.push('/users#' + order.user_id)"
                  >
                    {{ users[order.user_id].name }}
                  </span>
                </td>
                <td>{{ order.order_id }}</td>
                <td>{{ order.date }}</td>
                <td>{{ order.amount }}</td>
                <td>
                  <span class="btn btn-sm btn-warning" v-if="order.status == 0">
                    لم تتم
                  </span>
                  <span class="btn btn-sm btn-success" v-if="order.status == 1">
                    ناجحة
                  </span>
                  <span class="btn btn-sm btn-danger" v-if="order.status == 2">
                    فاشلة
                  </span>
                </td>
                <td>
                  <span v-if="order.manual != 'no'"> {{ order.manual }}</span>
                </td>
                <td>
                  <button
                    class="btn btn-block btn-sm btn-secondary"
                    v-b-modal.modal-1
                    @click="current = order"
                  >
                    <i class="fa fa-edit"></i>
                    تعديل الحالة
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="تعديل الطلب" hide-footer>
      <div class="form-group">
        <label for="">المبلغ</label>
        <input type="text" class="form-control" v-model="current.amount" />
      </div>
      <div class="form-group">
        <label for="">نوع الطلب (تلقائي ام يدوي)</label>
        <select class="form-control" v-model="current.manual">
          <option value="no">تفعيل تلقائي</option>
          <option value="yes">طلب يدوي</option>
          <option value="reseller">reseller</option>
        </select>
      </div>
      <div class="form-group">
        <label for="">حالة الطلب</label>
        <select class="form-control" v-model="current.status">
          <option :value="0">لم تتم</option>
          <option :value="1">ناجحة</option>
          <option :value="2">فاشلة</option>
        </select>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click="editNow()">
          تعديل الطلب <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { jsontoexcel } from "vue-table-to-excel";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      json: {
        head: [
          "order id",
          "user",
          "date",
          "amount",
          "amount with tax",
          "status",
          "manual",
        ],
        fileName:
          "whatsapp-api" +
          "-" +
          new Date().toISOString().split("T")[0] +
          "-" +
          ".csv",
      },
      current: {},
      users: {},
      orders: [],
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    $.post(api + "/admin/users/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        r.response.forEach((element) => {
          g.users[element._id] = element;
        });
        g.getOrders();
      })
      .catch(function () {
        alert("error server or internet");
      });
  },
  methods: {
    getOrders() {
      var g = this;
      g.loading = true;
      $.post(api + "/admin/orders/list", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.orders = r.response;
          g.loading = false;
        })
        .catch(function () {
          alert("error server or internet");
        });
    },
    editNow() {
      var g = this;
      $.post(api + "/admin/orders/edit", {
        jwt: this.user.jwt,
        order: g.current,
      })
        .then(function (r) {
          alert("تم التعديل بنجاح");
          g.getOrders();
        })
        .catch(function () {
          alert("error server or internet");
        });
    },
    onlySuccess() {
      var g = this,
        newarr = [];
      g.orders.forEach(function (a) {
        if (a.status == "1") {
          newarr.push(a);
        }
      });
      g.orders = newarr;
    },
    toExcel() {
      const { head, fileName } = this.json;
      // "order id", "user", "date", "amount", "amount with tax", "status", "manual"
      var v = this.orders.map((x) => {
        return {
          order_id: x.order_id,
          user_id: x.user_id,
          date: x.date,
          original: x.original,
          amount: x.amount,
          status: x.status,
          manual: x.manual,
        };
      });
      jsontoexcel.getXlsx(v, head, fileName);
    },
  },
};
</script>

<style>
</style>